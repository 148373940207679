/*
 * Custom
 */
$( document ).ready(function() {

    // Hamburger

    $('.header__mobile').on('click', function () {
       $('.header__nav_mob').fadeToggle();

    });

    // Scroll logo

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll > 0) {
            $('.header__logo a:first-of-type img').show();
            $('.header__logo a:nth-of-type(2) img').hide();
        } else {
            $('.header__logo a:first-of-type img').hide();
            $('.header__logo a:nth-of-type(2) img').show();
        }
    });

    // Clients slider

    var swiper0 = new Swiper('.swiper-container.clients_slider', {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            575: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 5,
            },
        }
    });

    // Swiper cases (mobile)

    var swiper1 = new Swiper('.swiper-container.cases_slider', {
        slidesPerView: 4,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            575: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        }
    });

    // Swiper leadership (mobile)

    var swiper2 = new Swiper('.swiper-container.leadership_slider', {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // Swiper gallery (mobile)

    var swiper3 = new Swiper('.swiper-container.gallery_slider', {
        navigation: {
            nextEl: '.next3',
            prevEl: '.prev3',
        },
    });

    // Scroll to

    $('.home_top__down a').bind('click.smoothscroll',function (e) {
            e.preventDefault();
            var target = this.hash,
                $target = $(target);
            $('html, body').stop().animate( {
                'scrollTop': $target.offset().top
            }, 1000, 'swing', function () {
                window.location.hash = target;
            } );
        } );

    // Team show more

    $('.leadership_item').on('mouseenter mouseleave',function () {

        $(this).toggleClass('active');
    });

    // Slider word

    var swiper4 = new Swiper('.swiper-container.swiper_word', {
        navigation: {
            nextEl: '.next4',
            prevEl: '.prev4',
        },
    });

    // Coming soon more

    // $('.btn_show').on('click', function () {
    //     if (jQuery(this).hasClass('active')) {
    //
    //         $('.btn_show').removeClass('active');
    //         $('.coming_soon__more').removeClass('show');
    //         $("html, body").animate({ scrollTop: 0 }, 1000);
    //         return false;
    //     }
    //     else
    //     {
    //
    //         $(this).addClass('active');
    //         $('.coming_soon__more').addClass('show');
    //         $('html, body').animate({
    //             scrollTop: $('#footer').offset().top - 20
    //         }, 1000);
    //     }
    //
    // });

    // Scroll to

    // $('.btn_header').bind('click.smoothscroll',function (e) {
    //     e.preventDefault();
    //     var target = this.hash,
    //         $target = $(target);
    //     $('html, body').stop().animate( {
    //         'scrollTop': $target.offset().top - 40
    //     }, 1000, 'swing', function () {
    //         window.location.hash = target;
    //     } );
    // } );

    // $('.coming_soon__more a').bind('click.smoothscroll',function (e) {
    //     e.preventDefault();
    //     var target = this.hash,
    //         $target = $(target);
    //     $('html, body').stop().animate( {
    //         'scrollTop': $target.offset().top - 40
    //     }, 1000, 'swing', function () {
    //         window.location.hash = target;
    //     } );
    // } );

    // If scrolled to

    // $.fn.isInViewport = function() {
    //     var elementTop = $(this).offset().top;
    //     var elementBottom = elementTop + $(this).outerHeight();
    //
    //     var viewportTop = $(window).scrollTop();
    //     var viewportBottom = viewportTop + $(window).height();
    //
    //     return elementBottom > viewportTop && elementTop < viewportBottom;
    // };
    //
    // $(window).on('resize scroll', function() {
    //     if ($('#footer').isInViewport()) {
    //         $('.header__logo a img:first-child').show();
    //         $('.header__logo a img:nth-of-type(2)').hide();
    //         $('.btn_show').addClass('active');
    //         $('.coming_soon__more').addClass('show');
    //     } else {
    //         $('.header__logo a img:first-child').hide();
    //         $('.header__logo a img:nth-of-type(2)').show();
    //         $('.btn_show').removeClass('active');
    //         $('.coming_soon__more').removeClass('show');
    //     }
    // });

});